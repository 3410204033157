import { TableOrderAdminInfo } from '../../types/tableordersmodels';
import React, {  useState , useContext, useEffect} from 'react'; 
import { DataGrid, GridColDef, GridRowData ,GridCellParams, GridSelectionModel} from '@material-ui/data-grid'; 
import QRCode from 'qrcode.react'; 
import { Popper, Grid  } from '@material-ui/core';
import './TableOrderAdminInfoTable.css';

interface Props {
	data: TableOrderAdminInfo[];
}

const TableOrderAdminInfoTable = ({ data }: Props) => {
	const [selectedTable, setSelectedTable] = useState<TableOrderAdminInfo | null>(null);
	const [pageSize, setPageSize] = useState<number>(10);   
	
	const [anchorTooltip, setAnchorTooltip] = useState<null | HTMLElement>(null);
		// hides the "kopiert" popup after a delay
		useEffect(() => {
			if (anchorTooltip == null) return;
			setTimeout(() => {
				setAnchorTooltip(null);
			}, 1200);
		}, [anchorTooltip]); 
	const columns: GridColDef[] = [
         
		{ field: 'storeNumber', headerName: 'Filialnummer' , flex: 1.5},
		{ field: 'storeName', headerName: 'Filialname', flex: 2.5 },
		{ field: 'tableID', headerName: 'Tischnummer', flex:1.5 }, 
		{ field: 'url', headerName: 'Url', flex:5.5, renderCell: (param) => {
			return (
				<div
					style={{ width: '100%', overflow: 'hidden' }}
					onMouseDown={(event) => event.preventDefault()}
					onDoubleClick={(event)=>	{
						if (selectedTable) {
							navigator.clipboard.writeText(selectedTable.url); 
							setAnchorTooltip(event.currentTarget);
						}}}
				>
					{param.row.url}
				</div>
			);
		}, },
         
	];

	return (
		<> 
                
			<Grid container spacing={3} className="toaitgrid">
                <Grid item xs={9}
                >
				<DataGrid 
					rows={data}
					columns={columns}
					rowsPerPageOptions={[5, 10, 25, 50]} 
					getRowId={(row: any) => `${row.storeNumber}-${row.tableID}` }
					pageSize={pageSize}
					onPageSizeChange={(newPagesize) => {
						setPageSize(newPagesize);
						
					}} 
					onRowClick={(element: GridRowData) =>
						setSelectedTable(element.row as TableOrderAdminInfo)
					}
				/>
				{/* popup after copying customerCard or userId */}
			{anchorTooltip != null && (
				<Popper anchorEl={anchorTooltip} open={anchorTooltip != null} placement="right">
					<div className="qrcodegrid__popper">kopiert</div>
				</Popper>
			)}
                </Grid>

                {
                selectedTable &&
                <Grid item xs={3} className="qrcodegrid__grid ">
                    
                    <div  className="qrcodegrid__div ">
                    <div className='qrcodegrid__text' > {`Filiale ${selectedTable.storeNumber} - ${selectedTable.storeName}`} </div>
                    <div  className='qrcodegrid__text' > {`Tisch ${selectedTable.tableID}`} </div>
                    <div > <QRCode height={300} width={300} style={{height:'300px', width:'300px'}} value={selectedTable.url} includeMargin  />
                    </div>
              </div> 
                </Grid> 
                }
                </Grid> 
		</>
	);
};
export default TableOrderAdminInfoTable;
